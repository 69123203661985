<template>
    <v-app>
        <v-app-bar 
            app
            color="primary"
            dark
            clipped-left
            elevation="0"
            height="50"
        >
            <div class="d-flex align-center primary--text font-weight-bold">
                <v-img
                alt="School Logo"
                class="shrink mr-2"
                contain
                src="../assets/logo.png"
                transition="scale-transition"
                width="40"
                />
                
            </div>
            <span v-html="schoolName"></span>
            <v-spacer></v-spacer>
            <v-btn
                outlined
                @click="logout"
            >
                Logout
            </v-btn>            
        </v-app-bar>

        <v-main class="grey lighten-5">
            <v-container fluid>
                <v-row>
                    <v-col
                        align="center"
                        justify="center"
                    >
                        <v-card
                            width="900"
                        >
                            <v-card-title>
                                {{ applicationYear }} Form Six (6) Applications
                                <v-spacer></v-spacer>

                                <v-btn
                                    color="primary"
                                    class="mr-3"
                                    dark
                                    @click="downloadAllApplications"
                                >
                                    <v-icon
                                        left
                                    >
                                        mdi-download
                                    </v-icon>
                                    <div class="d-flex flex-column" style="font-size:0.7rem">
                                        <span>All</span> 
                                        <span>Applications</span>
                                    </div>
                                </v-btn>
                               
                                <v-menu offset-y>
                                    <template
                                        v-slot:activator="{ on, attrs }"
                                    >
                                        <v-btn
                                            color="primary"
                                            class="mr-3"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon
                                                left
                                            >
                                                mdi-download
                                            </v-icon>
                                            <div class="d-flex flex-column" style="font-size:0.7rem">
                                                <span>Accepted</span> 
                                                <span>List</span>
                                            </div>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item link>
                                            <v-list-item-title
                                                @click="downloadSpreadsheet"
                                            >
                                                Excel Spreadsheet
                                            </v-list-item-title>                                            
                                        </v-list-item>
                                        <v-list-item link>
                                            <v-list-item-title
                                                @click="displayPDF"
                                            >
                                                PDF Document
                                            </v-list-item-title>                                            
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-menu 
                                    offset-y
                                    :close-on-content-click="false"
                                    v-model="menuApplicationsStatus"
                                >
                                    <template
                                        v-slot:activator="{ on, attrs }"
                                    >
                                        <v-btn
                                            color="primary"
                                            class="mr-3"
                                            outlined
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon
                                                left
                                            >
                                                {{ applicationsLockStatus.icon }}
                                            </v-icon>
                                            <div class="d-flex flex-column" style="font-size:0.7rem">
                                                <span>Applications</span> 
                                                <span>
                                                    {{ applicationsLockStatus.text }}
                                                </span>
                                            </div>
                                        </v-btn>
                                    </template>
                                    <v-card width="200">
                                        <v-card-text>
                                            <v-select
                                                v-model="applicationYear"
                                                :items="applicationPeriods"
                                                item-text="period"
                                                item-value="year"
                                                outlined
                                                hide-details="auto"
                                                dense
                                                label="Period"
                                                class="mb-4"
                                            ></v-select>
                                            <v-select
                                                v-model="applicationsLockStatus"
                                                :items="applicationsLockStatusOptions"
                                                item-text="text"
                                                return-object
                                                outlined
                                                hide-details="auto"
                                                dense
                                                label="Applications Status"
                                            ></v-select>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn
                                                block
                                                color="primary"
                                                @click="updateApplicationsLockStatus"
                                            >
                                                Update
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                                
                            </v-card-title>

                            <v-data-table
                                :headers="headers"
                                :items="applications"
                                :loading="loading"
                                height="60vh"
                                fixed-header
                                :search="search"
                            >
                                <template v-slot:top>
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        label="Search Application"
                                        single-line
                                        hide-details
                                        clearable
                                        class="mx-4 mb-4"
                                    ></v-text-field>
                                </template>

                                <template
                                    v-slot:[`item.status`]="{ item }"
                                >
                                   <v-menu
                                    :close-on-content-click="false"
                                    v-model="item.menu"
                                   >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                color="primary"
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                                block
                                                outlined
                                            >
                                                {{ item.status }}
                                                <v-icon right small>
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card width="300">
                                            <v-card-title>Edit Status</v-card-title>
                                            <v-card-text>
                                                <v-select
                                                    v-model="item.status"
                                                    label="Application Status"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    @change="updateStatus(item)"
                                                    chips
                                                    small-chips
                                                    deletable-chips
                                                    :items="status"
                                                    class="mb-4"
                                                ></v-select>
                                                 <v-select
                                                    label="Subjects Choice Option"
                                                    v-model="item.subjects_option"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    @change="updateStatus(item)"
                                                    chips
                                                    small-chips
                                                    deletable-chips
                                                    :items="subjectsOptions"
                                                ></v-select>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn 
                                                    text color="primary"
                                                    @click="closeEdit(item)"
                                                >
                                                    Close
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                   </v-menu>
                                   
                                </template>

                                <template v-slot:[`item.actions`]="{ item }">
                                    <div class="mb-2">
                                        <v-btn
                                            @click="viewApplication(item)"
                                            x-small
                                            color="primary"
                                            block
                                        >
                                            <v-icon 
                                                left
                                                small
                                            >
                                                mdi-file-outline
                                            </v-icon>
                                            View
                                        </v-btn>
                                    </div>
                                    <div>    
                                        <v-btn
                                            x-small
                                            class=""
                                            outlined
                                            block
                                            @click="deleteSelectedApplication(item)"
                                        >
                                            <v-icon
                                                left
                                                small
                                                
                                            >
                                                mdi-trash-can-outline
                                            </v-icon>
                                            Delete
                                        </v-btn>
                                    </div>    
                                </template>

                                <template v-slot:[`item.files`]="{ item }">

                                    <v-menu
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                medium                           
                                                v-bind="attrs"
                                                v-on="on"
                                                class="ml-3"
                                            >
                                                mdi-dots-vertical
                                            </v-icon>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                v-for="(file, index) in files"
                                                :key="index"
                                                link
                                                @click="displayFile(item, file)"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon
                                                        color="primary"
                                                        v-if="item[file.model]"
                                                    >
                                                        mdi-cloud-check
                                                    </v-icon>
                                                    <v-icon
                                                        v-else
                                                        color="red"
                                                    >
                                                        mdi-alert-circle
                                                    </v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>
                                                    {{ file.title }}
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>

                                </template>

                                <template v-slot:[`item.changelog`]="{ item }">
                                    <v-btn 
                                        outlined
                                        color="red" 
                                        dark 
                                        x-small 
                                        v-if="item.changelog.length !== 0"
                                        @click="viewChangeLog(item)"
                                    >
                                        <v-icon left>mdi-flag</v-icon>
                                        View
                                    </v-btn>
                                </template>
                            </v-data-table>

                        </v-card>
                    </v-col>    
                </v-row>
            </v-container>

            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"                    
            >
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>

            <v-dialog
                v-model="dialog"                      
                persistent
            >
                <v-card height="80vh">
                    <iframe ref="pdf" style="width:100%; height:100%" frameBorder="0" :src="src"></iframe>
                </v-card>

                <v-container
                    fluid
                    class="pa-0"
                >
                    <v-sheet>
                        <v-row
                            justify="end"
                            class="ma-0"
                        >

                            <v-btn
                                color="primary"
                                dark
                                @click="closeForm"
                                class="my-2 mr-4"
                            >
                                Close
                            </v-btn>

                        </v-row>
                    </v-sheet>
                </v-container>

            </v-dialog>

            <!-- Delete Dialog -->
            <v-dialog
                v-model="dialogDelete"
                width="500"
            >
                <v-card class="pa-4">
                    <v-card-text>
                        <v-alert
                            type="warning"
                            border="left"
                            text
                            
                            prominent
                            colored-border
                        >
                            {{ deleteMessage }}
                        </v-alert>                        
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="confirmDelete"
                        >
                            Delete
                        </v-btn>
                        <v-btn                            
                            text
                            color="primary"
                            @click="cancelDelete"
                            outlined
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Delete Dialog confirm -->
            <v-dialog
                v-model="dialogConfirm"
                width="500"
            >
                <v-card class="">
                    <v-card-text class="px-0 pb-0">
                        <v-alert
                            type="warning"
                            border="left"
                            text
                            prominent
                            colored-border
                        >
                            {{ confirmMessage }}
                        </v-alert>                        
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="confirmApplicationsLockStatusUpdate"
                        >
                            {{ applicationsLockStatus.confirm }}
                        </v-btn>
                        <v-btn                            
                            text
                            color="primary"
                            @click="dialogConfirm = false"
                            outlined
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Dialgo File Preview -->
            <v-dialog
                v-model="dialogFilePreview"
                :max-width="maxWidth"
                persistent
            >
                <v-card
                    v-if="!picturePreview"
                    height="80vh"
                >
                    <iframe ref="pdf" style="width:100%; height:100%" frameBorder="0" :src="src"></iframe>

                </v-card>

                <v-img
                    v-else
                    max-width="200"
                    :src="src"
                    class="mx-auto ma-6"
                ></v-img>

                <v-container
                    fluid
                    class="pa-0"
                >
                    <v-sheet>
                        <v-row
                            justify="end"
                            class="ma-0"
                        >

                            <v-btn
                                color="primary"
                                dark
                                @click="closeFilePreview"
                                class="my-2 mr-4"
                            >
                                Close
                            </v-btn>

                        </v-row>
                    </v-sheet>
                </v-container>

            </v-dialog>

            <!-- Dialog Changelog  -->
            <v-dialog
                v-model="dialogChangelog"
                width="500"
                persistent
            >
                <v-card>
                    <v-card-title>
                        {{ changelog.title }}
                        <v-spacer></v-spacer>
                        <v-btn color="red" icon small @click="dialogChangelog = false">
                            <v-icon>mdi-close</v-icon>
                            
                        </v-btn>
                    </v-card-title>

                    <v-card-text style="height:70vh; overflow:auto">
                        <v-timeline
                            dense
                            clipped
                        >
                            <v-timeline-item
                                v-for="(item, i) in changelog.items"
                                :key="i"
                                class="mb-4"
                                small
                            >
                                <v-row justify="space-between">
                                    <v-col cols="7">
                                        <div class="font-weight-bold">{{ item.title}}</div>
                                        <div>{{ item.description }}</div>
                                    </v-col>
                                    <v-col cols="5" class="text-caption">
                                        <strong>{{ item.updated_at }}</strong>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed @click="dialogChangelog = false">
                            Close
                            <v-icon right>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>

            <v-snackbar
                v-model="snackbar"
                :color="snackColor"
            >
                {{ snackText }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >Close</v-btn>
                </template>
            </v-snackbar>      
        </v-main>
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { format } from 'date-fns';
export default {
    created: function () {
        this.initialize()    
    },

    data: function () {
        return {
            overlay: false,
            applications: [],
            headers: [
                {text: 'Name', value: 'name'},
                {text: 'Application ID', value: 'application_id'},
                {text: 'Year', value: 'year'},
                {text: 'Birth Certificate Pin', value: 'birth_certificate_pin'},
                {text: 'Status', value: 'status', width: '120'},
                {text: 'Actions', value: 'actions', sortable: false},
                {text: 'Files', value: 'files', sortable: false},
                {text: 'Changelog', value: 'changelog', sortable: false},
            ],
            loading: false,
            search: '',
            dialog: false,
            src: '',
            dialogDelete: false,
            deleteMessage: '',
            snackbar: false,
            snackColor: '',
            snackText: '',
            deleteItem: {},
            status:['Accepted', 'Not Accepted'],
            subjectsOptions: [1,2],
            files: [
                {title: 'Birth Certificate', model: 'birth_certificate'},
                {title: 'Results Slip', model: 'results_slip'},
                {title: 'Transfer Form', model: 'transfer_form'},
                {title: 'Passport Photo', model: 'picture'},
                {title: 'Recommendation', model: 'recommendation_1'},
            ],
            dialogFilePreview: false,
            maxWidth: '',
            picturePreview: false,
            applicationsLockStatus: {
                locked: 0,
                text: 'Open',
                icon: 'mdi-lock-open-variant',
                confirm: 'Open Applications'
            },
            applicationsLockStatusOptions: [
                {
                    locked: 0,
                    text: 'Open',
                    icon: 'mdi-lock-open-variant',
                    confirm: 'Open Applications'
                },
                {
                    locked: 1,
                    text: 'Closed',
                    icon: 'mdi-lock-outline',
                    confirm: 'Close Applications'
                }
            ],
            menuApplicationsStatus: false,
            dialogConfirm: false,
            confirmMessage: '',
            applicationPeriods: [],
            applicationYear: null,
            dialogChangelog: false,
            changelog: {},
        }
    },

    computed: {
        ...mapGetters({
            schoolName: 'application/getSchoolName',
        }),
    },

    methods: {
        async initialize () {
            this.overlay = true;
            this.loading = true;
            this.applications = [];
            console.log('initializing');
            try {
                const promiseAllApplications = this.getAllApplications();
                const promiseApplicationsLockStatus = this.getApplicationsLockStatus();
                const promiseApplicationPeriods = this.getApplicationPeriods();
                const promiseCurrentApplicationPeriod = this.getCurrentApplicationPeriod();
                const [
                    { data: dataAllApplications },
                    { data: dataApplicationLockStatus },
                    { data: dataApplicationPeriods },
                    { data: dataCurrentApplicationPeriod }
                ] = await Promise.all([
                    promiseAllApplications,
                    promiseApplicationsLockStatus,
                    promiseApplicationPeriods,
                    promiseCurrentApplicationPeriod
                ])

                // const { data } = await this.getAllApplicaitons();
                this.mapApplications(dataAllApplications);
                // console.log(dataApplicationLockStatus);
                this.mapApplicationsLockStatus(dataApplicationLockStatus);
                // console.log(dataApplicationPeriods);
                this.mapApplicationPeriods(dataApplicationPeriods);
                // console.log(dataCurrentApplicationPeriod);
                this.setApplicationYear(dataCurrentApplicationPeriod.year);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
        },

        ...mapActions({
            getAllApplications: 'application/getAllApplications',
            deleteApplication: 'application/deleteApplication',
            adminSignOut: 'application/adminSignOut',
            postApplication: 'application/postApplication',
            downloadAcceptedSpreadsheet: 'application/downloadAcceptedSpreadsheet',
            postApplicationsLockStatus: 'application/postApplicationsLockStatus',
            getApplicationsLockStatus: 'application/getApplicationsLockStatus',
            getApplicationPeriods: 'application/getApplicationPeriods',
            getCurrentApplicationPeriod: 'application/getCurrentApplicationPeriod',
            downloadApplicationsSpreadsheet: 'application/downloadApplicationsSpreadsheet',
        }),

        ...mapMutations({
            setApplicationDelete: 'application/setApplicationDelete',
            setAuthenticated: 'application/setAuthenticated',
            setApplication: 'application/setApplication',
            setApplicationsLockStatus: 'application/setApplicationsLockStatus',
            setApplicationYear: 'application/setApplicationYear',
        }),

        mapApplications (data) {
            // console.log(data);
            data.forEach(record => {
                let status = record.status ? record.status : 'Edit';
                console.log(status)
                this.applications.push({
                    name: record.last_name + ', ' + record.first_name,
                    ...record,
                    status,
                    // application_id: record.application_id,
                    // email: record.email,
                    // year: record.year,
                    // birth_certificate_pin: record.birth_certificate_pin,
                    // date_of_birth: record.date_of_birth,
                    // status: status,
                    // menu: false,
                    // birth_certificate: record.birth_certificate,
                    // results_slip: record.results_slip,
                    // transfer_form: record.transfer_form,
                    // picture: record.picture,
                    // recommendation_1: record.recommendation_1
                })
            })
            this.loading = false;
        },

        mapApplicationPeriods (data) {
            this.applicationPeriods = [];
            data.forEach(record => {
                this.applicationPeriods.push({
                    period: record.year+"-"+(record.year+1),
                    ...record
                })
                if(record.current_year === 1) this.applicationYear = record.year
            })
            // console.log(this.applicationPeriods)
            let currentYear = new Date().getFullYear();
            // console.log(currentYear);
            this.applicationPeriods.unshift({
                period: currentYear+"-"+(currentYear+1),
                year: currentYear
            })
        },

        mapApplicationsLockStatus (data) {
            let locked = data.locked;
            let text = locked ? 'Closed' : 'Open';
            let icon = locked ? 'mdi-lock-outline' : 'mdi-lock-open-variant';
            let confirm = locked ? 'Close Applications' : 'Open Applications';
            this.applicationsLockStatus = {
                locked: locked,
                text: text,
                icon: icon,
                confirm: confirm
            }
        },

        viewApplication (item) {
            console.log(item);
            this.src = process.env.VUE_APP_API_URI + "/api/sixth-form-application-form/" + 
            item.application_id + "/" +
            item.year;
            this.dialog = true;
        },

        displayPDF () {
            this.src = process.env.VUE_APP_API_URI + "/api/sixth-form-application-accepted-pdf/" + 
            this.applicationYear;
            this.dialog = true;
        },

        downloadSpreadsheet () {
            let timestamp = format(new Date(), 'yyyyMMddhhmmss');
            let filename = this.applicationYear + " Accepted Form 6 " + timestamp +'.xlsx';
            this.overlay = true;
            this.downloadAcceptedSpreadsheet()
            .then(response => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                link.setAttribute('download', filename);
               
                document.body.appendChild(link);
                link.click();

                this.overlay = false;
                this.loading = false;
            })
            .catch( error => {
                if(error.response){
                    console.log(error.response);
                } 
                else{
                    console.log(error);
                } 

                this.overlay = false;

                this.snackText = 'An Error has occured, file could not be downloaded.';
                this.snackColor = "red";
                this.snackbar = true;
            })
        },

        closeForm () {
            this.dialog = false;
            this.src = '';
        },

        deleteSelectedApplication (item) {
            this.setApplicationDelete(item);
            this.deleteMessage = `Are you sure you want to delete ${item.application_id} ${item.name}`;
            this.dialogDelete = true;
            this.deleteItem = item;
        },

        
        async confirmDelete () {
            this.dialogDelete = false;
            this.overlay = true;
            try {
                const response = await this.deleteApplication();
                console.log(response);
                this.snackColor = "primary";
                this.snackText = `${this.deleteItem.application_id} deleted.`
                this.initialize();
            } catch (error) {
                this.snackColor = "red"
                this.snackText = `An Error has occured. ${this.deleteItem.application_id} not deleted.` 
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
            setTimeout(() => {
                this.snackbar = true;
            }, 500)
            
        },

        async updateStatus (item) {
            console.log(item);
            try {
                this.setApplication({
                    application_id: item.application_id,
                    birth_certificate_pin: item.birth_certificate_pin,
                    date_of_birth: item.date_of_birth,
                    status: item.status,
                    year: item.year,
                    subjects_option: item.subjects_option
                })
                const response = await this.postApplication();
                if(!item.status){
                    let itemIndex = this.applications.indexOf(item);
                    this.applications[itemIndex].status = 'Edit';
                }
                this.snackColor = "primary";
                this.snackText = "Status Updated"
                this.snackbar = true;
                console.log(response);
            } catch (error) {
                if(error.response){
                    console.log(error.response)
                }
                else console.log(error)
                this.snackColor = "red";
                this.snackText = "Error Occured. Status Not Updated"
                this.snackbar = true;
            }
        },

        cancelDelete () {
            this.dialogDelete = false;
        },

        async logout () {
            this.overlay = true;
            sessionStorage.clear();
            try {
                const response = await this.adminSignOut();
                console.log(response);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setAuthenticated(false);
            this.$router.replace('/');
        },

        displayFile (item, file) {
            console.log(item);
            console.log(file);
            let src = item[file.model]
            if(src && file.model != 'picture'){
                this.maxWidth = "80vw";
                this.src = src;
                this.picturePreview = false;
                this.dialogFilePreview = true;
            }
            else if(src && file.model == 'picture'){
                this.maxWidth = "300";
                this.picturePreview = true;
                this.src = src;
                this.dialogFilePreview = true;
            }
        },

        closeEdit (item) {
            console.log(item);
            this.applications.forEach(value => {
                if(
                    value.application_id === item.application_id &&
                    value.year == item.year
                ){
                    value.menu = false;
                }
            })
        },

        closeFilePreview () {
            this.src = '';
            this.dialogFilePreview = false;
        },

        updateApplicationsLockStatus () {
            this.menuApplicationsStatus = false;
            console.log(this.applicationsLockStatus)
            this.dialogConfirm = true;
            this.confirmMessage = `Are you sure you want to change ${this.applicationYear}-${this.applicationYear+1} applications status to ${this.applicationsLockStatus.text}`
        },

        async confirmApplicationsLockStatusUpdate () {
            // console.log('confirm update');
            this.dialogConfirm = false;
            this.confirmMessage = '';
            this.overlay = true;
            this.setApplicationsLockStatus(this.applicationsLockStatus.locked);
            this.setApplicationYear(this.applicationYear);
            try {
                await this.postApplicationsLockStatus();
                this.initialize();
                this.overlay = false;
                this.snackColor = "primary";
                this.snackText= "Applications status updated.";
                this.snackbar = true;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.overlay = false;
                this.snackColor = "red";
                this.snackText= "An error has occured applications status not updated.";
                this.snackbar = true;
            }
        },

        downloadAllApplications () {
            let timestamp = format(new Date(), 'yyyyMMddhhmmss');
            let filename = this.applicationYear + " Form 6 Applications" + timestamp +'.xlsx';
            this.overlay = true;
            this.downloadApplicationsSpreadsheet()
            .then(response => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                link.setAttribute('download', filename);
               
                document.body.appendChild(link);
                link.click();

                this.overlay = false;
                this.loading = false;
            })
            .catch( error => {
                if(error.response){
                    console.log(error.response);
                } 
                else{
                    console.log(error);
                } 

                this.overlay = false;

                this.snackText = 'An Error has occured, file could not be downloaded.';
                this.snackColor = "red";
                this.snackbar = true;
            })
        },

        viewChangeLog (item) {
            console.log('change log', item);
            this.changelog.title = `${item.application_id} Changelog`
            this.changelog.items = item.changelog;
            this.dialogChangelog = true;

        }
    }
}
</script>

<style scoped>
    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
        padding: 10px 16px;
    }
</style>
